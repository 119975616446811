/* src/App.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: #fff8e1; /* Jasnożółte, ciepłe tło */
  color: #333;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header */
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #DD1F26; /* Czerwony McDonald's */
  padding: 1rem;
  border-bottom: 5px solid #FFC72C; /* Żółty pasek pod spodem */
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 100px;
  height: 100px;
  margin-right: 1rem;
  object-fit: cover; /* Zapewnia, że obraz wypełnia cały obszar */
}

.header h1 {
  margin: 0;
  font-size: 1.8rem;
  color: #fff; /* Biały tekst na czerwonym tle */
  font-weight: bold;
}

.account {
  margin: 0;
  color: #fff; /* Biały tekst */
}

/* Buttons */
.btn {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: bold;
  transition: background 0.2s ease;
  background: #FFC72C; /* Żółty guzik */
  color: #DD1F26;      /* Czerwony tekst */
}

.btn:hover {
  background: #ffb81c; /* Nieco ciemniejszy żółty przy hover */
}

.btn.primary {
  background: #DD1F26; /* Czerwony McDonald's */
  color: #FFC72C;      /* Żółty tekst */
}

.btn.primary:hover {
  background: #c51b20; /* Nieco ciemniejszy czerwony */
}

.btn.music-btn {
  background: #FFC72C;
  color: #DD1F26;
  font-weight: bold;
}

.btn.music-btn:hover {
  background: #ffb81c;
}

/* Main Content */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.info-box, .action-box, .trading-links {
  background: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 2px solid #DD1F26; 
}

.info-box p, .trading-links p, .action-box h2, .action-box input {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.action-box h2 {
  margin-top: 0;
  color: #DD1F26; /* Nagłówek w czerwonym */
  font-weight: bold;
}

.input-field {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.stake-input-container {
  display: flex;
  gap: 0.5rem;
}

.max-btn {
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 4px;
  font-weight: bold;
  transition: background 0.2s ease;
  background: #DD1F26; /* Czerwony */
  color: #FFC72C;      /* Żółty tekst */
}

.max-btn:hover {
  background: #c51b20; /* Ciemniejszy czerwony */
}

.action-btns {
  display: flex;
  gap: 1rem;
}

/* Trading links */
.trading-links p {
  margin: 0.5rem 0;
}

.btn-link {
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  background: #DD1F26; /* czerwony */
  color: #FFC72C;      /* żółty tekst */
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background 0.2s ease;
}

.btn-link:hover {
  background: #c51b20; /* ciemniejszy czerwony na hover */
}

/* Responsywność */
@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-controls {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .action-btns {
    flex-direction: column;
    gap: 0.5rem;
  }

  .stake-input-container {
    flex-direction: column;
  }

  .max-btn {
    width: 100%;
  }
}
